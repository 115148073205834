@charset "utf-8";

@import "./assets/styles/color.scss";

$font-path: "./assets/styles/fonts/NationalPark/";

@font-face {
	src: url("#{$font-path}/NationalPark-Heavy.otf") format("otf");

	font-family: "NationalPark";
	font-weight: bold;
}

@font-face {
	src: url("#{$font-path}/NationalPark-regular.otf") format("otf");

	font-family: "NationalPark";
}

@font-face {
	src: url("#{$font-path}/NationalPark-Thin.otf") format("otf");

	font-family: "NationalPark";
	font-weight: lighter;
}

@font-face {
	src: url("#{$font-path}/NationalPark-Outline.otf") format("otf");

	font-family: "NationalPark-Outline";
}


* {
	font-family: "NationalPark", Arial, sans-serif;
	border-radius: 0!important;
}
#view-container {
	position: absolute;
	left: 72px;
	width: auto;
	right: 24px;
	top: 16px;
	bottom: 8px;
	& > .container {
		background-color: $tiles;
		padding: 8px;
	}
}