
/* stylelint-disable indentation */
@import "./src/assets/styles/color.scss";

body {
	background-color: $background;
  animation: switchColor 5s;
}

@keyframes switchColor {
  0% {
    background: $tiles;
  }
    100% {
    background: $background;
  }
}


#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #000000;
}

.navigation {
	width: 60px;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	background-color: #4b3030;
	border-right: 4px solid $confirm;
  animation: switchColorNav 5s;

  @keyframes switchColorNav {
      0% {
            background: $tiles;
          }
      100% {
            background: #4b3030;
          }
    }

  ul {
		list-style: none;
		height: 100%;
	}
	li {
		width: 56px;
		&:first-child {
			margin-bottom: 24px;
		}
		a {
			text-align: center;
			font-size: 2rem;
			i {
				color: $background;
				font-weight: lighter;
				font-size: 24px;
			}
			.logo {
				bottom: -3px;
				height: 30px;
				width: 30px;
				display: inline-block;
				position: relative;
				transform: scale(1.25);
			}
		}
		.router-link-active {
			background-color: $active;
		}
	}
}
