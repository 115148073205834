$tiles: rgba(175, 153, 126, 0.84);
$active: #836e55;
$confirm: #a6664f;
$font: #303030;
$background: #e8d7b6;
$error: rgba(255, 94, 0, 0.808);

/*
$tiles: #3B2E6D;
$active: #42DAD7;
$confirm: #E4417B;
$background: #FAFDFD;

$tiles: #121212;
$active: #30475E;
$confirm: #F05454;
$background: #F5F5F5;

$tiles: #40514E;
$active: #11999E;
$confirm: #30E3CA;
$background: #E4F9F5;

$tiles: #515661;
$active: #2E9F82;
$confirm: #68C170;
$background: #d8e3e7;
*/
